    .middle-grey-style-outline {
      border: #999999;
    }

    .white-color {
      color: #ffffff;
    }

    .btn-custom {
      background-color: #434343;
      color: white;
      border-color: #434343;
    }

    .btn-custom:hover,
    .btn-custom:focus,
    .btn-custom:active,
    .btn-custom.active,
    .open .dropdown-toggle.btn-custom {

      background-color: #434343;
      color: white;
      border-color: #434343;
    }

    .bar {
      width: 85%;
      border-radius: 100px;
      border: 2px;
      padding: 10px 10px 10px 10px;
      /* border-radius: 15px 50px 30px 5px; */
      margin: 0px 0;
      background-color: rgb(255, 2, 0);
      line-height: 0px;
      height: 80px;
      margin: 20px 0;
      /* display: flex; */
      /* align-items: stretch; */
    }

    .bar a {
      color: black;
    }

    .alignleft {
      padding-top: 7px;
      padding-left: 10px;
      float: left;
      text-align: left;
      width: 33.33333%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* border: 1px solid #000000; */
    }

    .alignleft3 {
      padding-top: 7px;
      padding-left: 10px;
      /* float: left; */
      /* text-align: left; */
      width: 46%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* border: 1px solid #000000; */
    }

    .aligncenter {
      padding-top: 10px;
      float: left;
      text-align: center;
      width: 33.33333%;
      /* font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis; */
      font-weight: bold;
      width: 33.33333%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .alignright {
      padding-top: 7px;
      padding-right: 10px;
      float: right;
      text-align: right;
      width: 33.33333%;
      /* border: 1px solid #000000; */
    }
    
    .alignright3 {
      padding-top: 7px;
      padding-right: 10px;
      /* float: right; */
      text-align: right;
      width: 50%;
      /* border: 1px solid #000000; */
    }

    .ellipsis{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .square {
      border-radius: 20px;
      background-color: #cccccc;
      padding: 30px 0;
      margin: 30px;
    }

    .square-select {
      border-radius: 20px;
      background-color: rgb(255, 2, 0);
      padding: 30px 0;
      margin: 30px;
    }

    .video-annotate {
      padding-left: 40px;
    }

    .flex-container {
      display: flex;
      align-items: stretch;
      /* background-color: #f1f1f1; */
    }

    .flex-container>div {
      /* background-color: DodgerBlue; */
      /* color: white; */
      /* padding-top: 40px; */
      margin: 0 10px;
      overflow: hidden;
      /* padding: 5px; */
      /* min-width: 0; */
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-direction: row;
      /* line-height: 75px; */
      /* font-size: 30px; */
    }

    .right {
      text-align: right;
      /* padding-left: 10px; */
      /* margin-top: 100px; */
    }

    .left {
      text-align: left;
      /* padding-left: 10px; */
      /* padding-right: 10px; */

    }



    .center {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-direction: row;
    }

.align-container{ width:100%; }
.align-left{ float: left;width:43%; overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;}
.align-right{ float: right;width:43%; }