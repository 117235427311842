.middle-grey-style-outline {
    border: #999999;
}

.white-color {
    color: #ffffff;
}

.btn-custom {
    background-color: #434343;
    color: white;
    border-color: #434343;
}

.btn-custom:hover,
.btn-custom:focus,
.btn-custom:active,
.btn-custom.active,
.open .dropdown-toggle.btn-custom {

    background-color: #434343;
    color: white;
    border-color: #434343;
}

.bar {
    width: 85%;
    border-radius: 100px;
    border: 2px;
    padding: 10px 10px 10px 10px;
    /* border-radius: 15px 50px 30px 5px; */
    margin: 0px 0;
    background-color: rgb(255, 2, 0);
    line-height: 0px;
    height: 80px;
    margin: 20px 0;
}

.bar a {
    color: black;
}

.alignleft {
    padding-left: 10px;
    float: left;
    text-align: left;
    width: 33.33333%;
}

.aligncenter {
    padding-top: 5px;
    float: left;
    text-align: center;
    width: 33.33333%;
}

.alignright {
    padding-right: 10px;
    float: left;
    text-align: right;
    width: 25.33333%;
}

.square {
    border-radius: 20px;
    background-color: #cccccc;
    padding: 30px 0;
    margin: 30px;
}

.square-select {
    border-radius: 20px;
    background-color: rgb(255, 2, 0);
    padding: 30px 0;
    margin: 30px;
}

.video-annotate {
    padding-left: 40px;
}



.check_box {
    display: none;
}

.check_box+label {
    background: no-repeat;
    background-color: transparent;
    border: none;
    outline: none;
    float: right;
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
    cursor: pointer;
}

.check_box:checked+label {
    background: no-repeat;
    height: 16px;
    width: 16px;
    display: inline-block;
    padding: 0 0 0 0px;
}


.checkboxStar {
    display: none;
}