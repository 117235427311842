h1 {
    font-size: 55px;
    margin-top: 40px;
  }

  .centered {
    text-align: center;
  }

  .white {
    color: #ffffff;
  }

  .advantage-right {
    text-align: right;
  }

  .increase-spacing {
    margin-top: 15px;
    padding-bottom: 25px;
  }

  .pad-bottom {
    padding-bottom: 10px;
  }

  .btn-tabs{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color: #222;
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    border-color: #3b78d8;
    border-width: medium;
    border: 4px solid #3b78d8;
    margin: 9px 9px 0px 0px;
    border-radius: 15px;
    
  }

  ul.tabsX li {
    background: none;
    display: inline-block;
    padding: 10px 30px;
    
    cursor: pointer;
    border-color: #3b78d8;
    border-width: medium;
    border: 2px solid #3b78d8;
    /* border-radius: 15px 15px 0px 0px; */
    border-radius: 15px;
    background: #3b78d8;
    color: white;
  }

  .active-tab{
    background: none;
    display: inline-block;
    padding: 10px 30px;
    cursor: pointer;
    border-color: #3b78d8;
    border-width: medium;
    border: 4px solid #3b78d8;
    /* border-radius: 15px 15px 0px 0px; */
    border-radius: 15px;
    background: #3b78d8;
    color: white;
  }

  .tab-content {
    display: none;
    /* background: #ededed; */
    padding: 15px;
  }

  .tab-content.current {
    display: inherit;
  }

  /* Vertically align elements */
  .vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }

  h3 {
    font-weight: bolder;
  }

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
